import App from './App';
import Store from "./Store/ConfigureStore";
import React from 'react';
import ReactDOM from 'react-dom';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { Provider } from 'react-redux';
import { ToastContainer, Slide } from "react-toastify";

// Import du CSS toastify
import "react-toastify/dist/ReactToastify.min.css";

let persistor = persistStore(Store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <PersistGate persistor={persistor}>
        <App />
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          transition={Slide}
        />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

