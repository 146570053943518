import React from 'react';

// Import du CSS
import "./Footer.css";

// Import des icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';

// Import du pdf
import chartre from '../../assets/charteDesDonneesPersonnelles.pdf';

function Footer() {
    return (
        <footer className="footer bg-white mt-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12  h-100 text-center  my-auto">
                        <ul className="list-inline">
                            <li className="list-inline-item mr-3 contact-icon-linkedin">
                                <a href="https://www.linkedin.com/in/chantal-vacquet-14523467/ " target="blank">
                                    <FontAwesomeIcon icon={faLinkedin} size='3x' />
                                </a>
                            </li>
                            <li className="list-inline-item ml-3 mr-3 contact-icon-facebook">
                                <a href="https://www.facebook.com/pegaseproprete.idsoft" target="blank">
                                    <FontAwesomeIcon icon={faFacebookSquare} size='3x' />
                                </a>
                            </li>
                            <li className="list-inline-item ml-3 mr-3 contact-icon-envelope">
                                <a href="mailto:c.vacquet@idsoft.net">
                                    <FontAwesomeIcon icon={faEnvelopeSquare} size='3x' />
                                </a>
                            </li>
                            <li className="list-inline-item ml-3 mr-3 contact-icon-copyright" data-toggle="modal" data-target="#mentionsLegales">
                                <FontAwesomeIcon icon={faCopyright} size='3x' />
                            </li>
                            {/* Modale */}
                            <div className="modal fade" id="mentionsLegales" tabindex="-1" role="dialog" aria-labelledby="mentionsLegalesTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="mentionsLegalesTitle">Mentions Légales</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p className="mentions-titre">1. Généralités</p>
                                            <p>En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans
                                                l’économie numérique, il est précisé aux utilisateurs du site idsoft.net l’identité des
                                                différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
                                            <p className="mentions-titre">Propriétaire : </p>
                                            <p className="text-left">
                                                <span className="font-weight-bold">Raison sociale :</span> Ingénierie et Développement Informatique – ID Soft
                                                <br /><span className="font-weight-bold">Siège social :</span> 28-26 allée du Clos Fleuri – 45000 ORLEANS
                                                <br /><span className="font-weight-bold">Tél.</span> 02 38 81 71 82
                                                <br /><span className="font-weight-bold">Forme juridique :</span> SAS
                                                <br /><span className="font-weight-bold">Capital social :</span> 40 000 €
                                                <br /><span className="font-weight-bold">RCS :</span> Orléans B 392 818 183
                                                <br /><span className="font-weight-bold">Code APE :</span> 6202A
                                                <br /><span className="font-weight-bold">N° de TVA intracommunautaire :</span> FR09392818183
                                                <br /><span className="font-weight-bold">Directeur de la publication :</span> Monsieur Jean-Michel RIFFAULT
                                                <br /><span className="font-weight-bold">Conception :</span> ID Soft
                                                <br /><span className="font-weight-bold align-baseline">Hébergeur : </span><span className="d-inline-block align-top">OVH <br /> Siège social au 2 rue Kellermann <br /> 59100 Roubaix - FRANCE <br /> <a href="https://www.ovh.com/fr/" target="_blank">www.ovh.com</a></span>
                                                <br /><span className="font-weight-bold">Crédit photo :</span> Licences sous stock.adobe.com - © charles taylor © kirill_makarov ©
                                                phonlamaiphoto © josefkubes © meen_na © Carsten Reisinger © Bavorndej © Evrymmnt
                                                © Patrick Daxenbichler © Jon Le-Bon
                                                <br />Pixabay.com
                                                <br />Canva Pro
                                                <br />Site réalisé à l’aide de la librairie BootStrap V4
                                            </p>
                                            <p className="mentions-titre">2. Description du site </p>
                                            <p>Le site idsoft.net est un site à caractère informatif. </p>
                                            <div className="list-group mb-4">
                                                <ul className="text-left">
                                                    <p className="text-center">Il a pour objet :</p>
                                                    <li className="list-group-item">De décrire l’ensemble des activités de la société</li>
                                                    <li className="list-group-item">D’informer sur les offres d’emploi en cours et permettre aux candidats potentiels
                                                        de déposer leur CV</li>
                                                    <li className="list-group-item">D’informer sur les ressources disponibles</li>
                                                    <li className="list-group-item">De recueillir les demandes de renseignements des visiteurs du site</li>
                                                </ul>
                                            </div>
                                            <p>Le site idsoft.net ainsi que les mentions légales peuvent être mis à jour ou modifiés à
                                                tout moment. Nous vous invitons à visiter le site et/ou à prendre connaissance des
                                                Mentions Légales régulièrement. </p>
                                            <p className="mentions-titre">3. Propriété intellectuelle et contrefaçons. </p>
                                            <p>ID Soft est propriétaire des droits de propriété intellectuelle ou détient les droits
                                                d’usage de son logo et des éléments accessibles sur le site, notamment les textes,
                                                images, graphismes, logo, icônes... </p>
                                            <p>Les marques et logos reproduits sur ce site sont déposés par les sociétés qui en sont
                                                propriétaires. </p>
                                            <p>Toute représentation totale ou partielle de ce site par quelque procédé que ce soit,
                                                sans l’autorisation expresse de l’exploitant du site Internet est interdite et constituerait
                                                une contrefaçon sanctionnée par les articles L 335-2 et suivants du Code de la propriété
                                                intellectuelle. Les marques de l’exploitant du site Internet et de ses partenaires, ainsi
                                                que les logos figurant sur le site sont des marques (semi-figuratives ou non) déposées.
                                                Toute reproduction totale ou partielle de ces marques ou de ces logos, effectuée à
                                                partir des éléments du site sans l’autorisation expresse de l’exploitant du site Internet
                                                ou de son ayant-droit est donc prohibée, au sens de l’article L713-2 du Code de la
                                                Propriété Intellectuelle.</p>
                                            <p className="mentions-titre">4. Gestion des données personnelles.</p>
                                            <p>Nous vous invitons à prendre connaissance de la <a href={chartre} target="_blank">Charte ID Soft sur les Données
                                                Personnelles</a>
                                            </p>
                                            <p>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du
                                                6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et
                                                la Directive Européenne du 24 octobre 1995. </p>
                                            <p>Des données personnelles peuvent être collectées depuis notre site internet
                                                www.idsoft.net par le biais de formulaires que les visiteurs sont amenés à compléter de
                                                leur propre initiative, s’ils le souhaitent. </p>
                                            <p>Dans ce cas précis, les données collectées sont : Nom, Prénom, Adresse postale, Mail,
                                                Téléphone, éventuellement votre Curriculum Vitae sous forme de fichier joint. </p>
                                            <p>Les données collectées sur le Site sont destinées aux seuls services concernés d’ID Soft
                                                et conservées durant une période limitée et proportionnée par rapport à la finalité du
                                                traitement. En dehors des cas dans lesquels il existe une obligation d'archivage, les
                                                données qui ne présentent plus d’intérêt seront supprimées de tous supports et
                                                sauvegardes.</p>
                                            <p>Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier
                                                1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un
                                                droit d’accès, de rectification et d’opposition aux données personnelles le concernant,
                                                en effectuant sa demande par mail à rgpd@idsoft.net . </p>
                                            <p>Aucune information personnelle de l’utilisateur du site idsoft.net n’est publiée à l’insu
                                                de l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à
                                                des tiers.</p>
                                            <p className="mentions-titre">5. Liens hypertextes et cookies</p>
                                            <p>Le site idsoft.net peut contenir des liens hypertextes vers des sites tiers, partenaires ou
                                                non. Ces sites ne sont pas sous le contrôle de la société ID Soft qui décline toute
                                                responsabilité quant au contenu de ces sites. L’insertion de tels liens dans le site
                                                n’implique en aucun cas quelque approbation de leur contenu par la société ID Soft. </p>
                                            <p>À ce jour, le Site idsoft.net n’utilise aucun cookie ou autre traceur d’activité.</p>
                                            <p className="mentions-titre">6. Droit applicable et attribution de juridiction.</p>
                                            <p>Tout litige en relation avec l’utilisation du site idsoft.net est soumis au droit français. Si
                                                un différend survenait, les parties concernées s’efforceraient de le régler à l'amiable
                                                préalablement à toute action de justice. </p>
                                            <p>En cas de litige persistant, il serait porté devant les tribunaux compétents de la
                                                juridiction d’Orléans. </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* fin Modale */}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export { Footer };