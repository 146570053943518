import { BASE_URL } from "../../Util/Const"
import { useJsonRequest, POST } from "ids-reacttools/es/Data";
import { InputField, CheckboxField } from "ids-reacttools/es/Fields";
import { validateEmail, validateString } from "ids-reacttools/es/Utils";
import React, { useState } from "react";
import { toast } from "react-toastify";

// Import des composants et const
import { RESSOURCE_CONTACT } from "./API/ContactAPI";

// Import du CSS
import "./Contact.css";

// #region FONCTION COMPOSANT
function Contact() {

    // #region INITIALISATION
    // Initialisation des variables
    const emptyData = {
        toEmail: "service",
        objet: null,
        message: null,
        email: null
    };

    // Initialisation du state
    const [data, setData] = useState({ ...emptyData });
    // #endregion

    // #region REQUEST
    // Requête envoyer une actualite
    const requestPostMail = useJsonRequest({
        url: BASE_URL,
        resource: RESSOURCE_CONTACT,
        command: POST,
        sessionIdObligatory: false
    });
    // #endregion

    // #region EVENT
    // Changement d'une valeur
    const handleChange = (event) => {
        // Mise à jour des données
        let newData = { ...data };
        if (event.target.name === "toEmail") {
            if (event.target.value === "Pôle Services") {
                newData[event.target.name] = "service";
            } else {
                newData[event.target.name] = "progiciel";
            }
        } else {
            newData[event.target.name] = event.target.value;
        }
        setData(newData);
    };

    // Submit pour l'envoi du mail
    const submit = () => {
        let saisieOk = true;
        let emailOk = true;

        // Récupération de la saisie
        let email = data.email;
        let objet = data.objet;
        let message = data.message;

        // Vérification de la saisie
        if (!validateString(email)) {
            saisieOk = false;
        } else {
            // Vérification de l'email
            emailOk = validateEmail(email);
        }

        if (!validateString(message) || !validateString(objet)) {
            saisieOk = false;
        }

        if (saisieOk && emailOk) {
            requestPostMail(data);
            toast.success("L'email à bien été envoyé");

            // RAZ du fichier
            let newData = { ...data };
            newData["nomFichier"] = null;
            newData["data"] = null;
            newData["extension"] = null;
            newData["hasFile"] = false;
            setData(newData);

        } else {
            // Affichage d'un message d'erreur
            if (!saisieOk) {
                toast.error("Veuillez renseigner les informations manquantes");
            }
            if (!emailOk) {
                toast.error("L'email n'est pas valide");
            }
        }
    };
    // #endregion

    return (
        <section id="contact" className="container" data-aos="fade-up">
            <h2 className="mt-5 mb-4 text-dark font-weight-bold">CONTACTEZ-NOUS !</h2>
            <div className="row form-signin">
                <div className="col-md-6">
                    <input type="email" className="form-control mx-2" placeholder="Email" name="email" onChange={e => handleChange(e)} />
                    <select className="form-control m-2" id="select" name="toEmail" onChange={e => handleChange(e)}>
                        <option>Pôle Services</option>
                        <option>Pôle Progiciels</option>
                    </select>
                    <input className="form-control m-2" placeholder="Objet de la demande" name="objet" onChange={e => handleChange(e)} />
                    <button className="btn btn-lg btn-dark btn-block m-2" onClick={submit}>Envoyer la demande</button>
                </div>
                <div className="col-md-6">
                    <div className="form-label-group">
                        <textarea className="form-control mx-2" rows="7" name="message" placeholder="Description de la demande" onChange={e => handleChange(e)}></textarea>
                    </div>
                </div>
            </div>
        </section>
    );
};

export { Contact };