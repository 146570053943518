import React from 'react';
import "./Header.css";

// #region FONCTION COMPOSANT
function Margin() {
    // #region INTERFACE
    return (
        <div className="margin">     
        </div>
    );
    // #endregion
};
// #endregion

export { Margin };
