import { Header } from "./Components/Header/Header";
import { Margin } from "./Components/Header/Margin";
import { Accueil } from "./Components/Accueil/Accueil";
import { Contact } from "./Components/Contact/Contact";
import { Footer } from "./Components/Footer/Footer";
import React, { useEffect } from "react";

// Import du CSS
import './App.css';

// Import de Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Import des animations
import AOS from "aos";
import "aos/dist/aos.css";

// #region FONCTION COMPOSANT
function App() {

    // #region HOOK D'EFFET
    useEffect(() => { 
      AOS.init({duration: 1200});
    }, [] );

  // #region INTERFACE
  return (
    <div className="App">
      <Header />
      <Margin />
      <Accueil />
      <Contact />
      <Footer />
    </div>
  );
  // #endregion
};
 // #endregion

export default App;
