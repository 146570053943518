import { manageDataResource } from "ids-reacttools/es/Data";
import { manageConfirmDialog } from "ids-reacttools/es/Dialogs";
import { manageLoading } from "ids-reacttools/es/Loading";
import { manageSession, manageConnectedUser } from "ids-reacttools/es/Store";
import { createStore } from "redux";
import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";


const rootPersistConfig = {
  key: "siteids",
  storage: storage
};

export default createStore(
  persistCombineReducers(rootPersistConfig, {
    manageConnectedUser,
    manageConfirmDialog,
    manageDataResource,
    manageLoading,
    manageSession,
  })
);
