import React from 'react';

// Import du CSS
import "./Accueil.css";

// Import des Assets
import progiciel from "../../assets/progiciel.png";
import service from "../../assets/service.png";

// #region FONCTION COMPOSANT
function Accueil() {
    // #region INTERFACE
    return (
        <section id="accueil" >
            <div className="row no-gutters">
                <div className="col-xl-6" data-aos="fade-right">
                    <div className="my-5 mx-5 transform">
                        <a href="/idsoft/progiciels/" className="text-decoration-none">
                            <img className="img-fluid image" src={progiciel} alt="Logiciels specialisés pour les entreprises de propreté" />
                            <h2 className="text-center title text-dark font-weight-bold">Progiciels pour<br />Entreprises de Propreté</h2>
                        </a>
                    </div>
                </div>
                <div className="col-xl-6" data-aos="fade-left">
                    <div className="my-5 mx-5 transform">
                        <a href="/idsoft/services/" className="text-decoration-none">
                            <img className="img-fluid image" src={service} alt="Prestation de service dans l'informatique" />
                            <h2 className="text-center title text-dark font-weight-bold">Entreprise de<br />Services du Numérique</h2>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
    // #endregion
};
// #endregion

export { Accueil }