import React from 'react';

// Import du CSS
import "./Header.css";

// Import des assets
import logoHeader from "../../assets/logo-header.png";

// #region FONCTION COMPOSANT
function Header() {
    // #region INTERFACE
    return (
        <header className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow">
            <div className="container-fluid mx-5">
                <img src={logoHeader} alt="logo IDSoft" />
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item transform">
                            <a className="nav-link text-title2" href="/idsoft/progiciels">Progiciels</a>
                        </li>
                        <li className="nav-item transform">
                            <a className="nav-link text-title2" href="/idsoft/services/">Services</a>
                        </li>
                        <li className="nav-item transform">
                            <a className="nav-link text-title2" href="#contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
    // #endregion
};
// #endregion

export { Header };
